import React from 'react'
import ReactDOM from 'react-dom/client'
import { Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from '@/reportWebVitals'
import '@/components/i18n'
import '@/index.css'
import { ConfigProvider } from 'antd-mobile'
import arLocale from '@/locales/antd-mobile/ar'
import enLocale from 'antd-mobile/cjs/locales/en-US'

import Share from '@/pages/Share/share'
import InviteFriends from './pages/Invite/InviteFriends';
import InvitePhoneRegister from './pages/Invite/PhoneRegister';
import InvitePhoneVerification from './pages/Invite/PhoneVerification';
import InvitePhoneCreatePassword from './pages/Invite/PhoneCreatePassword';
import GoogleCallback from './pages/Callback/Google'
import UpdateIosUpdate from './pages/Update/IosUpdate/IosUpdate';


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const locale = window.lang === 'ar' ? arLocale : enLocale
root.render(
  <ConfigProvider locale={locale}>
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route path="download/">
            <Route index element={<Share />} />
            <Route path='index.html' element={<Share />} />
          </Route>
          <Route path='invite'>
            <Route path="invite-friends/:inviteNaadiId" element={<InviteFriends />} />
            <Route path="invite-friends/phone-register" element={<InvitePhoneRegister />} />
            <Route path="invite-friends/phone-verification/:phone/:countryCode" element={<InvitePhoneVerification />} />
            <Route path="invite-friends/phone-create-psw/:phone/:countryCode" element={<InvitePhoneCreatePassword />} />
          </Route>
          <Route path="callback">
            <Route path="google" element={<InviteFriends />} />
          </Route>
          <Route path="update">
            <Route index element={<UpdateIosUpdate />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </ConfigProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
