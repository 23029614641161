import { service } from '@/utils/net'

/* Invite */
// H5第三方账号注册
export function thirdRegister(
  params: Invite.InviteThirdRegisterParams
): Promise<Response> {
  return service<Response>({
    method: 'POST',
    url: '/acc/h5/third/register',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}
//国家列表
export function getPhoneCountryList(searchKey?: string) {
  return service<Response<Invite.PhoneCountryList[]>>({
    method: 'GET',
    url: 'country/info/list',
    params: {
      searchKey
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}
//获取验证码
export function obtainSmsVerifyCode(
  params: Invite.ObtainSmsVerifyCodeParams
): Promise<Response> {
  return service<Response>({
    method: 'POST',
    url: 'sms/obtainSmsVerifyCode',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}
//校验注册验证码
export function verifySignSmsCode(
  params: Omit<Invite.ObtainSmsVerifyCodeParams, 'smsType'> & {
    smsCode: string
  }
): Promise<Response> {
  return service<Response>({
    method: 'POST',
    url: 'acc/verifySignSmsCode',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}
//检查手机号是否已注册
export function checkPhone(
  params: Omit<Invite.ObtainSmsVerifyCodeParams, 'smsType'>
): Promise<Response<boolean>> {
  return service<Response<boolean>>({
    method: 'POST',
    url: 'acc/checkPhone',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}
//H5手机号码注册
export function h5PhoneRegister(
  params: Omit<Invite.ObtainSmsVerifyCodeParams, 'smsType'> & {
    password: string
    inviteNaadiId: string
  }
): Promise<Response> {
  return service<Response>({
    method: 'POST',
    url: 'acc/h5/register',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}
//获取邀请链接信息
export function getInviteLink(
  inviteNaadiId: string
): Promise<Response<Invite.InviteLink>> {
  return service<Response<Invite.InviteLink>>({
    method: 'GET',
    url: 'h5doings/inviteFriends/activity/getInviteLink',
    params: {
      inviteNaadiId
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}
