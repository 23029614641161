import React, { useCallback, useEffect, useRef, useState } from 'react'
import './InviteFriends.scss'

import ImgFIcon from '@/images/facebook-login-icoin.png';
import ImgGIcon from '@/images/google-login-icon.png';
import ImgPIcon from '@/images/phone-login-icon.png';
import ImgLogInSuccess from '@/images/log-in-success.webp';

import Header from '@/components//Header';
import Loading from '@/components/Loading';
import { HelmetProvider } from 'react-helmet-async'
import Meta from '@/components/Meta'

import { useTranslation } from 'react-i18next';
import { initFbSDK, isIOS } from '@/utils/tool';
import config from '@/config';
import { thirdRegister, getInviteLink } from '@/services/invite';
import { toast } from '@/utils/bridge';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserInfo } from '@/services/google';

function InviteFriends() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const refTimeout = useRef<NodeJS.Timeout>()

    const { inviteNaadiId } = useParams()
    const refInviteNaadiId = useRef(inviteNaadiId)
    refInviteNaadiId.current = inviteNaadiId

    const [loading, setLoading] = useState(false)
    const [isLogIn, setIsLogIn] = useState(false)

    const [inviteLinkData, setInviteLinkData] = useState<Invite.InviteLink>()

    const [countdown, setCountdown] = useState(5)
    const refCountdown = useRef(countdown)
    refCountdown.current = countdown

    const [link, setLink] = useState<string>()

    const fetchInviteLink = useCallback(async () => {
        if (!refInviteNaadiId.current) return
        const { code, message, data } = await getInviteLink(refInviteNaadiId.current)

        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        setInviteLinkData(data)
    }, [])

    const getGoogleUserInfo = useCallback(async (token: string) => {
        const data = await getUserInfo(token)
        const identityToken = token
        const userId = data.user_id
        const email = data.email
        return { identityToken, userId, email }
    }, [])

    const fetchThirdRegister = useCallback(async (params: Invite.InviteThirdRegisterParams) => {
        setLoading(true)
        // 获取第三方用户信息
        const { code, message } = await thirdRegister(params)
        setLoading(false)
        if (code !== 200) {
            toast(message)
            return
        }
        setIsLogIn(true)
    }, [])

    const facebookSignInSuccess = useCallback((response: any) => {
        if (!refInviteNaadiId.current || !response.accessToken || !response.userID) return
        fetchThirdRegister({
            inviteNaadiId: refInviteNaadiId.current,
            loginType: 3,
            identityToken: response.accessToken,
            userId: response.userID
        })
    }, [])

    const handleGoogle = useCallback(() => {
        // Google's OAuth 2.0 endpoint for requesting an access token
        const oauth2Endpoint = config.GOOGLE_OAUTH2_ENDPOINT;

        // Create <form> element to submit parameters to OAuth 2.0 endpoint.
        const form = document.createElement('form');
        form.setAttribute('method', 'GET'); // Send as a GET request.
        form.setAttribute('action', oauth2Endpoint);

        // Parameters to pass to OAuth 2.0 endpoint.
        const params: { [key: string]: string } = {
            'client_id': config.GOOGLE_CLIENT_ID,
            'redirect_uri': location.origin + '/callback/google',
            'response_type': 'token',
            'scope': 'https://www.googleapis.com/auth/drive.metadata.readonly',
            'include_granted_scopes': 'true',
            'state': 'pass-through value'
        };

        // Add form parameters as hidden input values.
        for (const p in params) {
            const input = document.createElement('input');
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', p);
            input.setAttribute('value', params[p]);
            form.appendChild(input);
        }

        // Add form to page and submit it to open the OAuth 2.0 endpoint.
        document.body.appendChild(form);
        form.submit();
    }, [])

    const handleFacebook = useCallback(() => {
        if (window.FB) {
            window.FB.login(function (response: any) {
                if (response.status === 'connected') {
                    facebookSignInSuccess(response.authResponse)
                }
            }, { scope: 'public_profile,email' })
        }
    }, [])

    const handlePhone = useCallback(() => {
        navigate('/invite/invite-friends/phone-register')
    }, [])

    const handleDownload = useCallback(() => {
        const isoUrl = 'https://apps.apple.com/us/app/naadi/id1668512187'
        const androidSchema = 'weipai://h5.naadi.microparty.com/app/MainActivity'
        const androidUrl =
            'https://play.google.com/store/apps/details?id=com.microparty.naadi.live'
        if (isIOS) {
            window.location.href = isoUrl
        } else {
            refTimeout.current = setTimeout(function () {
                window.location.href = androidUrl
            }, 500)
            setLink(androidSchema)
        }
    }, [])

    useEffect(() => {
        if (inviteNaadiId) {
            localStorage.setItem('inviteNaadiId', inviteNaadiId)
        }
        if (isLogIn) {
            const timer = setInterval(() => {
                setCountdown(prev => prev - 1)
                //倒计时结束
                if (refCountdown.current <= 1) {
                    handleDownload()
                    clearInterval(timer)
                }
            }, 1000)
        }
    }, [isLogIn])

    const handleGoogleLogin = useCallback(async () => {
        const quries = location.hash.slice(1).split('&').reduce<{ [key: string]: string }>((ret, val) => {
            const [k, v] = val.split('=')
            ret[k] = v
            return ret
        }, {})
        const accessToken = quries['access_token']
        const userInfo = await getGoogleUserInfo(accessToken)
        const inviteNaadiId = localStorage.getItem('inviteNaadiId')
        await fetchThirdRegister({
            ...userInfo,
            loginType: 2,
            inviteNaadiId: inviteNaadiId || ''
        })
    }, [inviteNaadiId])

    useEffect(() => {
        if (inviteNaadiId) {
            sessionStorage.setItem('inviteNaadiId', inviteNaadiId)
        }
    }, [inviteNaadiId])

    useEffect(() => {
        const isFacebookLogin = location.search.slice(1).split('&').includes('isLogIn=1')
        const isGoogleLogin = location.href.indexOf('pass-through') > -1 && location.href.indexOf('access_token') > -1
        if (isGoogleLogin) {
            handleGoogleLogin()
        } else if (isFacebookLogin) {
            setIsLogIn(!!isFacebookLogin)
        } else {
            initFbSDK()//引入facebook sdk
        }
        fetchInviteLink()
    }, [])

    return <>
        <HelmetProvider>
            <Meta title={t('Invite.InviteFriends.HeaderTitle').toString()} />
            <div className='pg-guild-invite-friends'>
                <Header hideArrow title={t('Invite.InviteFriends.HeaderTitle').toString()}></Header>
                {loading && <Loading />}
                <video poster='' src={require('@/images/background-video.mp4')} muted autoPlay loop></video>
                <div className='pg-guild-invite-friends__header'>
                    <img src={isLogIn ? ImgLogInSuccess : inviteLinkData?.inviterAvatar} alt="" />
                    <span>{t(`Invite.InviteFriends.${!isLogIn ? 'JoinUs' : 'LogInSuccess'}`)}</span>
                    <span>{t(`Invite.InviteFriends.${!isLogIn ? 'CollectExclusiveGiftForPalyGame' : 'YouCanPlayGame'}`)}</span>
                </div>
                <div className='pg-guild-invite-friends__icons'>
                    {inviteLinkData?.rewardList?.map((item, i) =>
                        <div key={i}><img src={item?.rewardIcon} alt="" /></div>
                    )}
                </div>
                {isLogIn ?
                    <div className='pg-guild-invite-friends__download' onClick={handleDownload}>
                        <span>{t('Common.Download')}:</span>
                        <span>{countdown}s</span>
                    </div>
                    :
                    <div className='pg-guild-invite-friends__btns'>
                        <div className='pg-guild-invite-friends__btns-item' onClick={handleGoogle}>
                            <img src={ImgGIcon} alt="" />
                            <span>{t('Invite.Google')}</span>
                        </div>
                        <div className='pg-guild-invite-friends__btns-item' onClick={handleFacebook}>
                            <img src={ImgFIcon} alt="" />
                            <span>{t('Invite.Facebook')}</span>
                        </div>
                        <div className='pg-guild-invite-friends__btns-item' onClick={handlePhone}>
                            <img src={ImgPIcon} alt="" />
                            <span>{t('Invite.Phone')}</span>
                        </div>
                    </div>
                }
                <div className='pg-guild-invite-friends__tips'>
                    <span>{t('Invite.InviteFriends.Tips')}</span>
                    <span>{t('Invite.InviteFriends.Tips1')}</span>
                </div>
            </div>
            {link && (
                <iframe style={{ display: 'none' }} src={link} title="download" />
            )}
        </HelmetProvider>
    </>
}

export default InviteFriends