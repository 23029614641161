import React, { useCallback, useEffect, useRef, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import Meta from '@/components/Meta'
import { useTranslation } from 'react-i18next'
import './share.scss'

export default function Download() {
  const { t } = useTranslation()
  const refTimeout = useRef<NodeJS.Timeout>()
  const [link, setLink] = useState<string>()

  const handleDownload = useCallback(() => {
    const isoUrl = 'https://apps.apple.com/us/app/naadi/id1668512187'
    const androidSchema = 'weipai://h5.naadi.microparty.com/app/MainActivity'
    const androidUrl =
      'https://play.google.com/store/apps/details?id=com.microparty.naadi.live'
    // const androidUrl = 'market://details?id=com.microparty.naadi'
    if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
      window.location.href = isoUrl
      // } else if (navigator.userAgent.match(/android/i)) {
    } else {
      refTimeout.current = setTimeout(function () {
        window.location.href = androidUrl
      }, 500)
      setLink(androidSchema)
    }
  }, [])

  useEffect(() => {
    function onVisibilityChange() {
      if (document.hidden) {
        clearTimeout(refTimeout.current)
      }
    }
    window.addEventListener('visibilitychange', onVisibilityChange)
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [])
  return (
    <HelmetProvider>
      <Meta title={t('Share.Title').toString()} />
      <div className="pg-dl">
        <div className="pg-dl__btn" onClick={handleDownload} />
      </div>
      {link && (
        <iframe style={{ display: 'none' }} src={link} title="download" />
      )}
    </HelmetProvider>
  )
}
