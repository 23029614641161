import React, {
  memo,
  useEffect,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useCallback
} from 'react'
import { t } from 'i18next'
import './Country.scss'
import { toast } from '@/utils/bridge'
import { getPhoneCountryList } from '@/services/invite'
import Loading from '@/components/Loading'
import { Input, Popup, SearchBar } from 'antd-mobile'
import { onlyNumber } from '@/utils/format'

type CountryDataProps = {
  inputPhone: string
  countryCode: string
}
type CountryProps = {
  setCanNext?: (bol: boolean) => void
}

const Country = (props: CountryProps, ref: any) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [openCountry, setOpenCountry] = useState(false)

  const refTimer = useRef<ReturnType<typeof setTimeout> | null>(null)

  const [inputState, setInputState] = useState<{
    inputPhone: string
    countryCode: string
    countryAreaCode: string
    countryNationalFlag: string
    searchCountry: string
  }>({
    inputPhone: '',
    countryCode: '',
    countryAreaCode: '',
    countryNationalFlag: '',
    searchCountry: ''
  })
  const refInputState = useRef(inputState)
  refInputState.current = inputState

  const [countryList, setCountryList] = useState<Invite.PhoneCountryList[]>([])

  useEffect(() => {
    fetchCountryList()
  }, [])

  // 获取国家列表
  const fetchCountryList = useCallback(async (searchKey?: string) => {
    setLoading(true)
    const res = await getPhoneCountryList(searchKey)
    setLoading(false)
    const { code, message, data } = res as Response<Invite.PhoneCountryList[]>
    if (code !== 200) {
      toast(message)
      return
    }
    setCountryList([...data])
    if (!searchKey) {
      setInputState({
        ...refInputState.current,
        countryCode: data[0].countryCode,
        countryAreaCode: data[0].countryAreaCode,
        countryNationalFlag: data[0].countryNationalFlag
      })
    }
  }, [])

  const handleInputChange = useCallback((value: string) => {
    const val = onlyNumber(value)
    setInputState({
      ...refInputState.current,
      inputPhone: val
    })
    props.setCanNext && props.setCanNext(!!val)
  }, [props.setCanNext])

  const handleCountry = useCallback((country: Invite.PhoneCountryList) => {
    setOpenCountry(false)
    setInputState({
      ...refInputState.current,
      countryCode: country.countryCode,
      countryAreaCode: country.countryAreaCode,
      countryNationalFlag: country.countryNationalFlag
    })
  }, [])

  const handleSearchChange = useCallback((keywords: string) => {
    refTimer.current && clearTimeout(refTimer.current)
    refTimer.current = setTimeout(() => {
      fetchCountryList(keywords)
    }, 500)
  }, [])
  useImperativeHandle(ref, () => ({
    inputPhone: inputState.inputPhone,
    countryCode: inputState.countryCode,
  }))

  return <>
    <div className='cmp-invite-phone-register-country__input-box'>
      <div onClick={() => setOpenCountry(true)}>
        <img src={inputState.countryNationalFlag} alt="" />
        <span>{inputState.countryAreaCode}</span>
      </div>
      <Input type='tel' inputMode="numeric" pattern="\d*" placeholder={t('Invite.InviteFriends.InputPhoneNumber').toString()} clearable value={inputState.inputPhone} onChange={handleInputChange} />
    </div>
    <Popup
      visible={openCountry}
      className="cmp-invite-phone-register-country country-popup"
      onMaskClick={() => setOpenCountry(false)}>
      <div className="cmp-invite-phone-register-country">
        {loading && <Loading />}
        <div className="cmp-invite-phone-register-country-title">{t('Common.Country')}</div>
        <div className="cmp-invite-phone-register-country-search">
          <SearchBar placeholder={t('Invite.InviteFriends.SearchForCountry').toString()} onChange={handleSearchChange} />
        </div>
        <div className="cmp-invite-phone-register-country-list">
          {countryList?.map((item) => (
            <div
              key={item.countryCode}
              className="cmp-invite-phone-register-country-item"
              onClick={() => handleCountry(item)}>
              <div className="cmp-invite-phone-register-country-item-left">
                <img
                  className="cmp-invite-phone-register-country-item-select"
                  alt="img"
                  src={item.countryNationalFlag}
                />
                <span>{item.countryName}</span>
              </div>
              <span className='cmp-invite-phone-register-country-item-right'>
                {item.countryAreaCode}
              </span>
            </div>
          ))}
        </div>
      </div>
    </Popup>

  </>
}

export default memo(forwardRef<CountryDataProps, CountryProps>(Country))
