import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import './PhoneCreatePassword.scss'

import ImgArrowBlack from '@/images/arrow-black.png';

import Header from '@/components//Header';
import Loading from '@/components/Loading';

import { useTranslation, Trans } from 'react-i18next';
import clsx from 'clsx';
import { Input } from 'antd-mobile';
import { EyeInvisibleOutline, EyeOutline } from 'antd-mobile-icons'
import { h5PhoneRegister } from '@/services/invite';
import { toast } from '@/utils/bridge';
import { DESAndBase64Encrypt } from '@/utils/tool';

function BottomTips({ text, onClick }: { text: string, onClick: () => void }) {
    return <span onClick={onClick}>{text}</span>
}
function PhoneCreatePassword() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false)
    const params = useParams()

    const [loading, setLoading] = useState(false)
    const refLoading = useRef(loading)
    refLoading.current = loading

    const [state, setState] = useState<{
        phone: string
        countryCode: string
        password: string
        inviteNaadiId: string
    }>({
        phone: '',
        countryCode: '',
        password: '',
        inviteNaadiId: ''
    })
    const refState = useRef(state)
    refState.current = state

    const canLogIn = useMemo(() => {
        return state.password.length >= 6 && state.password.length <= 20
    }, [state])

    const fetchH5PhoneRegister = useCallback(async () => {
        const canFetch = Object.values(refState.current).every(item => !!item)

        if (!canFetch || refLoading.current) return

        setLoading(true)
        const { code, message } = await h5PhoneRegister({
            phone: refState.current.phone,
            countryCode: refState.current.countryCode,
            password: DESAndBase64Encrypt(refState.current.password),
            inviteNaadiId: refState.current.inviteNaadiId,
        })
        setLoading(false)
        if (code !== 200) {
            toast(message)
            return
        }
        navigate(`/invite/invite-friends/${refState.current.inviteNaadiId || 0}?isLogIn=1`)
    }, [])

    const handleBottomTips = useCallback((index: number) => {
        switch (index) {
            case 0:
                location.href = 'https://h5.naadi.microparty.com/privacy/terms'
                break;
            case 1:
                location.href = 'https://h5.naadi.microparty.com/privacy'
                break;
            default:
                break;
        }
    }, [])

    const handleInputChange = useCallback((value: string) => {
        setState({
            ...refState.current,
            password: value
        })
    }, [])

    const handleLogIn = useCallback(() => {
        if (canLogIn) {
            fetchH5PhoneRegister()
        }
    }, [canLogIn])

    useEffect(() => {
        setState({
            ...refState.current,
            phone: params.phone || '',
            countryCode: params.countryCode || '',
            inviteNaadiId: sessionStorage.getItem('inviteNaadiId') || ''
        })
    }, [params])

    return (
        <div className='pg-guild-invite-phone-create-psw'>
            {loading && <Loading />}
            <Header title={t('Invite.InviteFriends.CreatePassword').toString()} arrowImage={ImgArrowBlack}></Header>
            <div className='pg-guild-invite-phone-create-psw__content'>
                <div className='pg-guild-invite-phone-create-psw__top-tips'>{t('Invite.InviteFriends.CreatePasswordTips')}</div>
                <div className='pg-guild-invite-phone-create-psw__input-box'>
                    <Input
                        placeholder={t('Invite.InviteFriends.Password').toString()}
                        clearable
                        type={visible ? 'text' : 'password'}
                        onChange={handleInputChange}
                    />
                    <div className='pg-guild-invite-phone-create-psw__input-box-eye'>
                        {!visible ? (
                            <EyeInvisibleOutline onClick={() => setVisible(true)} />
                        ) : (
                            <EyeOutline onClick={() => setVisible(false)} />
                        )}
                    </div>
                </div>
                <div onClick={handleLogIn} className={clsx('pg-guild-invite-phone-create-psw__next-btn', { 'can-next': canLogIn })}>{t('Invite.InviteFriends.LogIn')}</div>
                <div className='place'></div>
                <div className='pg-guild-invite-phone-create-psw__bottom-tips' >
                    <Trans
                        i18nKey="Invite.InviteFriends.PhoneRegisterBottomTips"
                        components={{ terms: <BottomTips onClick={() => handleBottomTips(0)} text={t('Invite.InviteFriends.TermsOfService')} />, policy: <BottomTips onClick={() => handleBottomTips(1)} text={t('Invite.InviteFriends.PrivacyPolicy')} /> }}
                    />
                </div>
            </div>
        </div>
    )
}

export default PhoneCreatePassword