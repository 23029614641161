const config = {
  reqMode: 'net',
  net: {
    host: process.env.REACT_APP_API_URL
  },
  encryptKey: 'd7ddcfc27878144829e116c405801af6',
  // GOOGLE_CLIENT_ID: '325080511799-p9vofol1drgdhvoh5nb8lb36lka80a12.apps.googleusercontent.com',
  GOOGLE_OAUTH2_ENDPOINT: 'https://accounts.google.com/o/oauth2/v2/auth',
  GOOGLE_CLIENT_ID:
    '325080511799-2shfns2gqbt6goqkjrge6deinqhupmpq.apps.googleusercontent.com',

  FACEBOOK_APP_ID: '1252560828806169'
}
export default config
