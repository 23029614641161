import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import './PhoneVerification.scss'

import ImgArrowBlack from '@/images/arrow-black.png';

import Header from '@/components//Header';
import { NumberKeyboard, PasscodeInput } from 'antd-mobile';
import Loading from '@/components/Loading';

import { useTranslation, Trans } from 'react-i18next';
import clsx from 'clsx';
import { obtainSmsVerifyCode, verifySignSmsCode } from '@/services/invite';
import { toast } from '@/utils/bridge';

function BottomTips({ text, onClick }: { text: string, onClick: () => void }) {
    return <span onClick={onClick}>{text}</span>
}
function PhoneVerification() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const params = useParams()
    const refTimer = useRef<NodeJS.Timer | undefined>()

    const [loading, setLoading] = useState(false)
    const refLoading = useRef(loading)
    refLoading.current = loading

    const [countdown, setCountdown] = useState(0)

    const [state, setState] = useState<{
        phone: string
        countryCode: string
        smsCode: string
    }>({
        phone: '',
        countryCode: '',
        smsCode: ''
    })
    const refState = useRef(state)
    refState.current = state

    const fetchSms = useCallback(async () => {
        if (!refState.current.phone || !refState.current.countryCode || refLoading.current) return

        setLoading(true)
        const { code, message } = await obtainSmsVerifyCode({
            countryCode: refState.current.countryCode,
            phone: refState.current.phone,
            smsType: 1
        })
        setLoading(false)

        if (code !== 200) {
            toast(message)
            return
        }
        setCountdown(60)
        refTimer.current = setInterval(() => {
            setCountdown(prev => prev - 1)
        }, 1000)
    }, [])

    const fetchVerifySignSmsCode = useCallback(async () => {
        if (!refState.current.phone || !refState.current.countryCode || !refState.current.smsCode || refLoading.current) return

        setLoading(true)
        const { code, message } = await verifySignSmsCode({
            countryCode: refState.current.countryCode,
            phone: refState.current.phone,
            smsCode: refState.current.smsCode
        })
        setLoading(false)

        if (code !== 200) {
            toast(message)
            return
        }

        return new Promise<boolean>((resolve) => {
            resolve(true)
        })
    }, [])

    const handleBottomTips = useCallback((index: number) => {
        switch (index) {
            case 0:
                location.href = 'https://h5.naadi.microparty.com/privacy/terms'
                break;
            case 1:
                location.href = 'https://h5.naadi.microparty.com/privacy'
                break;
            default:
                break;
        }
    }, [])

    const handleInputChange = useCallback((value: string) => {
        setState({
            ...refState.current,
            smsCode: value
        })
    }, [])

    const handleNext = useCallback(async () => {
        const isPass = await fetchVerifySignSmsCode()
        if (isPass) {
            navigate(`/invite/invite-friends/phone-create-psw/${refState.current?.phone}/${refState.current?.countryCode}`)
        }
    }, [])

    useEffect(() => {
        setState({
            ...refState.current,
            phone: params.phone || '',
            countryCode: params.countryCode || ''
        })
        setTimeout(() => {
            if (params.phone && params.countryCode) {
                fetchSms()
            }
        }, 0);
    }, [params])

    useEffect(() => {
        if (countdown <= 0) {
            clearInterval(refTimer.current)
        }
    }, [countdown])
    return (
        <div className='pg-guild-invite-phone-verification'>
            {loading && <Loading />}
            <Header title={t('Invite.InviteFriends.Verification').toString()} arrowImage={ImgArrowBlack}></Header>
            <div className='pg-guild-invite-phone-verification__content'>
                <div className='pg-guild-invite-phone-verification__top-tips'><span>{t('Invite.InviteFriends.WeNeedToSendSMSTo')}</span><span>{state.phone}</span></div>
                <div className='pg-guild-invite-phone-verification__input-box'>
                    <PasscodeInput plain seperated keyboard={<NumberKeyboard />} onChange={handleInputChange} />
                </div>
                {countdown > 0 ?
                    <div className='pg-guild-invite-phone-verification__center-tips'>{t('Invite.InviteFriends.NotReceived')}{countdown + 's'}</div>
                    : <div className='pg-guild-invite-phone-verification__resend' onClick={fetchSms}>{t('Invite.InviteFriends.Resend')}</div>}
                <div onClick={handleNext} className={clsx('pg-guild-invite-phone-verification__next-btn', { 'can-next': state.smsCode.length >= 6 })}>{t('Invite.InviteFriends.Next')}</div>
                <div className='place'></div>
                <div className='pg-guild-invite-phone-verification__bottom-tips' >
                    <Trans
                        i18nKey="Invite.InviteFriends.PhoneRegisterBottomTips"
                        components={{ terms: <BottomTips onClick={() => handleBottomTips(0)} text={t('Invite.InviteFriends.TermsOfService')} />, policy: <BottomTips onClick={() => handleBottomTips(1)} text={t('Invite.InviteFriends.PrivacyPolicy')} /> }}
                    />
                </div>
            </div>
        </div>
    )
}

export default PhoneVerification