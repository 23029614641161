import config from '@/config';
import CryptoJS from 'crypto-js';

const ua = navigator.userAgent
export const isAndroid = ua.indexOf('Android') !== -1 || ua.indexOf('Linux') !== -1
export const isIpad = !!((/macintosh|mac os x/i.test(navigator.userAgent) && window.screen.height > window.screen.width && !navigator.userAgent.match(/(iPhone\sOS)\s([\d_]+)/)) || navigator.userAgent.match(/(iPad).*OS\s([\d_]+)/))
export const isIOS = /iphone|ipad|ipod/i.test(ua) || !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || isIpad
export const isHuawei = /Huawei|HarmonyOS/i.test(ua) || ua.includes("HuaweiBrowser")
export const isPc = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|SymbianOS|Windows Phone/i.test(ua) === false && !isIOS

//判断是不是阿语
export const isAr = (str: string) => {
    return /[\u0600-\u06FF\u0750-\u077F]/.test(str);
}
//px转rem
export function px2rem(px: number) {
    return `${px / 37.5}rem`;
}
// 获取用户uid和ticket
export function getAuthInfo() {
    let auth
    try {
        const authStr = localStorage.getItem('auth') || ''
        auth = JSON.parse(authStr)
    } catch (e) {
        return
    }
    return auth
}
//des + base64加密
export function DESAndBase64Encrypt(dataToEncrypt: string) {
    const keyHex = CryptoJS.enc.Utf8.parse(config.encryptKey);
    const encrypted = CryptoJS.DES.encrypt(dataToEncrypt, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
}
// 解析jwt
export function parseJwt(token: string) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );
    return JSON.parse(jsonPayload)
}
//获取google SDK
export function initGoogleSDK(callback?: () => void) {
    if (!window.google) {
        // 创建script标签引入google的sdk
        const script = document.createElement('script')
        script.src = 'https://accounts.google.com/gsi/client'
        script.async = true
        script.defer = true
        script.onload = () => {
            callback && callback()
        }
        document.body.appendChild(script)
    } else {
        callback && callback()
    }
}
//获取facebook sdk
export function initFbSDK(callback?: () => void) {
    if (!window.FB) {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: config.FACEBOOK_APP_ID,
                // cookie: true,
                xfbml: true,
                version: 'v19.0'
            })
        }
        // 创建script标签引入facebook的sdk
        const script = document.createElement('script')
        script.src = 'https://connect.facebook.net/en_US/sdk.js'
        script.async = true
        script.defer = true
        script.crossOrigin = "anonymous"
        script.onload = () => {
            callback && callback()
        }
        document.body.appendChild(script)
    } else {
        callback && callback()
    }
}