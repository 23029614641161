import { service } from '@/utils/net'

// 获取app下载链接
export function getApkDownloadLink():Promise<Response<Update.DownloadLink>> {
    return service<Response<Update.DownloadLink>>({
        method: 'GET',
        url: 'h5doings/official/getApk',
    })
        .then((res) => res.data)
        .catch((e) => e)
}