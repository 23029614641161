import React, { useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import './PhoneRegister.scss'

import ImgArrowBlack from '@/images/arrow-black.png';

import Header from '@/components//Header';
import Country from './components/Country';
import Loading from '@/components/Loading';

import { useTranslation, Trans } from 'react-i18next';
import clsx from 'clsx';
import { checkPhone } from '@/services/invite';
import { toast } from '@/utils/bridge';

function BottomTips({ text, onClick }: { text: string, onClick: () => void }) {
    return <span onClick={onClick}>{text}</span>
}
function PhoneRegister() {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const refLoading = useRef(loading)
    refLoading.current = loading

    const refCountry = useRef<React.ElementRef<typeof Country>>(null)
    const [canNext, setCanNext] = useState(false)

    const handleBottomTips = useCallback((index: number) => {
        switch (index) {
            case 0:
                location.href = 'https://h5.naadi.microparty.com/privacy/terms'
                break;
            case 1:
                location.href = 'https://h5.naadi.microparty.com/privacy'
                break;
            default:
                break;
        }
    }, [])

    const handleNext = useCallback(async () => {
        if (!refCountry.current?.inputPhone || !refCountry.current?.countryCode || refLoading.current) return

        setLoading(true)
        const { code, data, message } = await checkPhone({
            countryCode: refCountry.current.countryCode,
            phone: refCountry.current.inputPhone,
        })
        setLoading(false)

        if (code !== 200) {
            toast(message)
            return
        }
        //true已注册 flase未注册
        if (data) {
            toast(t('Invite.InviteFriends.HasBeenRegistered'))
            return
        }
        navigate(`/invite/invite-friends/phone-verification/${refCountry.current?.inputPhone}/${refCountry.current?.countryCode}`)
    }, [])
    return (
        <div className='pg-guild-invite-phone-register'>
            {loading && <Loading />}
            <Header title={t('Invite.Phone').toString()} arrowImage={ImgArrowBlack}></Header>
            <div className='pg-guild-invite-phone-register__content'>
                <div className='pg-guild-invite-phone-register__top-tips'>{t('Invite.InviteFriends.PhoneRegisterTopTips')}</div>
                <Country
                    ref={refCountry}
                    setCanNext={setCanNext}
                />
                <div onClick={handleNext} className={clsx('pg-guild-invite-phone-register__next-btn', { 'can-next': canNext })}>{t('Invite.InviteFriends.Next')}</div>
                <div className='place'></div>
                <div className='pg-guild-invite-phone-register__bottom-tips' >
                    <Trans
                        i18nKey="Invite.InviteFriends.PhoneRegisterBottomTips"
                        components={{ terms: <BottomTips onClick={() => handleBottomTips(0)} text={t('Invite.InviteFriends.TermsOfService')} />, policy: <BottomTips onClick={() => handleBottomTips(1)} text={t('Invite.InviteFriends.PrivacyPolicy')} /> }}
                    />
                </div>
            </div>
        </div>
    )
}

export default PhoneRegister