import { isIOS } from "@/utils/tool";
import { useCallback } from "react"

export function useDownload() {
    const download = useCallback((link: string) => {
        if (isIOS) {
            window.location.href = link
        } else {
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = link;
            document.body.appendChild(iframe);
        }
    }, [])

    return {
        download
    }
}