import { base } from 'antd-mobile/cjs/locales/base'
import { mergeLocale } from 'antd-mobile/cjs/utils/merge-locale'

const ar = mergeLocale(base, {
  locale: 'العربية',
  common: {
    confirm: 'تأكيد',
    cancel: 'إلغاء',
    loading: 'جاري التحميل',
    close: 'إغلاق',
  },
  Calendar: {
    markItems: ['الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت', 'الأحد'],
    renderYearAndMonth: (سنة: number, شهر: number) => `${سنة}/${شهر}`,
  },
  Cascader: {
    placeholder: 'اختيار',
  },
  Dialog: {
    ok: 'اوك',
  },
  DatePicker: {
    tillNow: 'إلى الآن',
  },
  ErrorBlock: {
    default: {
      title: 'عفوًا ، حدث خطأ ما',
      description: 'من فضلك انتظر دقيقة وحاول مرة أخرى',
    },
    busy: {
      title: 'عفوا ، لا يتم التحميل',
      description: 'محاولة لتحديث الصفحة',
    },
    disconnected: {
      title: 'الشبكة مشغولة',
      description: 'محاولة لتحديث الصفحة',
    },
    empty: {
      title: "لا يمكن العثور على ذلك ...",
      description: 'هل تريد تجربة بحث جديد؟',
    },
  },
  InfiniteScroll: {
    noMore: 'لا أكثر',
    failedToLoad: 'فشل في التحميل',
    retry: 'إعادة المحاولة',
  },
  Input: {
    clear: 'واضح',
  },
  Mask: {
    name: 'Mask',
  },
  Modal: {
    ok: 'اوك',
  },
  PullToRefresh: {
    pulling: 'قم بالتمرير لأسفل للتحديث',
    canRelease: 'حرر التحديث على الفور',
    complete: 'تم التحديث بنجاح',
  },
  SearchBar: {
    name: 'Search Bar',
  },
  Slider: {
    name: 'Slider',
  },
  Stepper: {
    decrease: 'ينقص',
    increase: 'يزيد',
  },
  Switch: {
    name: 'Switch',
  }
})

export default ar