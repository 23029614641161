import React, { useCallback, useEffect } from 'react'
import './IosUpdate.scss'
import ImgUpdateIosBtn from '@/images/update/update-ios-btn.png';
import { getApkDownloadLink } from '@/services/update';
import { toast } from '@/utils/bridge';
import { useRefState } from '@/hooks';
import { isIOS } from '@/utils/tool';
import { useDownload } from '@/hooks';

function IosUpdate() {
    const [, setLink, refLink] = useRefState({ android: '', ios: '' })

    const { download } = useDownload()

    const handleDownload = useCallback(() => {
        const link = isIOS ? refLink.current.ios : refLink.current.android
        download(link)
    }, [])

    const fetchApkDownloadLink = useCallback(async () => {
        const res = await getApkDownloadLink()
        if (!res) return
        const { data, message, code } = res
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return

        setLink({
            android: res.data.officialAndApk,
            ios: res.data.officialIosApk
        })
    }, [])

    useEffect(() => {
        fetchApkDownloadLink()
    }, [])
    return (
        <div className='pg-ios-update'>
            <img onClick={handleDownload} className='pg-ios-update__btn' src={ImgUpdateIosBtn} alt="" />
        </div >
    )
}

export default IosUpdate