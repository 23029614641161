import axios from "axios";
import config from "../config";
import { getUserInfo } from "./bridge";

export const service = axios.create({
  baseURL: config.net.host,
  timeout: 30000
})

service.interceptors.request.use(
  async config => {
    const auth = await getUserInfo()
    config.params = {
      ...config.params,
      ...auth,
      ...['lang', 'deviceId'].reduce<{ [x: string]: string }>((ret, d) => {
        switch (d) {
          case 'lang':
            ret.language = localStorage.getItem(d) || ''
            break
          default:
            ret[d] = localStorage.getItem(d) || ''
        }
        return ret
      }, {})
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)


// 重试次数，共请求3次
const retry = 3
// 请求的间隙
const retryDelay = 1000

service.interceptors.response.use(
  res => {
    return res
  },
  error => {
    const { config, code, message } = error
    //网络波动/cors跨域会进行重试
    if (code === 'ERR_NETWORK' || message === 'Network Error' || !window.navigator.onLine) {
      config.retryCount = config.retryCount || 0;
      if (config.retryCount >= retry) {
        // 达到最大重试次数，直接返回错误
        return Promise.reject(error);
      }

      config.retryCount++;

      // 创建新的 Promise 来进行重试
      return new Promise<void>((resolve) => {
        setTimeout(() => resolve(), retryDelay || 0);
      }).then(() => {
        return service(config);
      });
    }
    //其他情况直接返回error
    return Promise.reject(error);
  }
)